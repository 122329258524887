import { Breadcrumbs, Button, Chip, FormHelperText } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import PrimaryButton from "../../../components/forms/button/PrimaryButton";
import ReactSelectBasic from "../../../components/forms/react-selector/ReactSelecte";
import ReactRte from "../../../components/forms/TextEditer/ReactRte";
import { gameApis } from "../../../services/games/gameApi";
import {
  ArrayToReactSelectOutput,
  draftToString,
  stringToDraft,
} from "../../../utils/helper";
import "./Category.scss";

const CreateCategory = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const [categoryDescription, setGameDescription] = useState("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [categoryArray, setCategoryArray] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>({
    categoryDescription: "",
    gameType: "",
  });

  const getAllCategory = async () => {
    try {
      const response: any = await gameApis.getGameTypes();
      if (response.status === 200) {
        setCategoryArray(response?.data?.response);
      }
    } catch (error: any) {
      if (error.status === 400) {
        toast.error(error.msg);
      }
    }
  };

  const getCategoryById = async (categoryId: string) => {
    try {
      const response: any = await gameApis.getCategoryById(categoryId);
      console.log("response", response?.data?.response);
      initialValues.gameType = response?.data?.response?.name;
      initialValues.categoryDescription = stringToDraft(
        response?.data?.response?.description
      );

      setInitialValues({ ...initialValues });
    } catch (error) {
      console.log("getCategoryById error:", error);
    }
  };

  useEffect(() => {
    if (categoryId) {
      getCategoryById(categoryId);
      setIsEdit(true);
    }
    getAllCategory();
  }, []);

  const validationSchema = Yup.object().shape({
    categoryDescription: Yup?.object()?.test(
      "has text",
      "Cannot save an empty note",
      (value: any) => {
        return value?.getCurrentContent()?.hasText(); //boolean
      }
    ),
    gameType: Yup?.string()?.required("Game Type is required"),
  });

  const handleAddCategory = async (
    payload: any,
    setSubmitting: any,
    resetForm: any
  ) => {
    try {
      console.log("payload", payload, isEdit);

      if (!isEdit) {
        try {
          const response = await gameApis.createCategory(payload);
          toast.success(response?.data?.msg);
          navigate("/category");
          setSubmitting(false);
        } catch (createError) {
          console.error("Error adding game:", createError);
          toast.error("Error adding game.");
          setSubmitting(false);
        }
      } else {
        try {
          const response = await gameApis.updateCategory(categoryId, payload);
          toast.success(response?.data?.msg);
          navigate("/category");
          setSubmitting(false);
        } catch (updateError) {
          console.error("Error updating game:", updateError);
          toast.error("Error updating game.");
          setSubmitting(false);
        }
      }
      resetForm();
    } catch (error: any) {
      if (error.status === 400) {
        return toast.error(error.msg);
      }
      toast.error(error.msg);
    }
  };

  const categoryArrayOption = useMemo(() => {
    return ArrayToReactSelectOutput(categoryArray);
  }, [categoryArray]);

  return (
    <div className='category-form-container'>
      <div className='d-flex justify-content-between align-items-center m-3 category-form-page-title-container'>
        <span className='page-title'>
          {isEdit ? `Update Category` : `Create Category`}
        </span>
        <Breadcrumbs aria-label='breadcrumb'>
          <Chip
            label='Category'
            onClick={() => navigate("/category")}
            sx={{
              cursor: "pointer",
              "&:hover, &:focus": {
                backgroundColor: "white" /* emphasize(backgroundColor, 0.06) */,
              },
              "&:active": {
                boxShadow: 1 /* theme.shadows[1] */,
                backgroundColor: "while" /* emphasize(backgroundColor, 0.12) */,
              },
            }}
          />
          <span>{isEdit ? `Update Category` : `Create Category`}</span>
        </Breadcrumbs>
      </div>
      <div className='p-3 d-flex flex-column gap-4 category-form-container'>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validateOnBlur
          validateOnChange
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log("values", values);

            handleAddCategory(
              {
                // ...values,
                description: draftToString(values.categoryDescription),
              },
              setSubmitting,
              resetForm
            );
          }}
          validationSchema={validationSchema}
        >
          {(formikProps) => {
            return (
              <>
                <form onSubmit={formikProps.handleSubmit}>
                  <div className='row category-form-info-container'>
                    <p className='m-0 p-3 text-start form-fields-title'>
                      Category Information
                    </p>
                    <div className='row m-0 category-info-container'>
                      <div className='d-flex flex-column gap-4 mb-3 p-3 col-sm-12 col-lg-12 category-info-first-div'>
                        <div className='row category-fields g-3'>
                          <div className='col-lg-4'>
                            <ReactSelectBasic
                              isRequired
                              label='Game Type'
                              name='gameType'
                              value={{
                                label: formikProps.values.gameType,
                                value: formikProps.values.gameType,
                              }}
                              theme={(theme: any) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#C0C0C0",
                                  primary: "grey",
                                },
                              })}
                              error={
                                formikProps.errors.gameType &&
                                formikProps.touched.gameType
                                  ? formikProps.errors.gameType
                                  : null
                              }
                              isRtl={false}
                              isClearable={false}
                              isSearchable={false}
                              options={categoryArrayOption}
                              onChange={(valueObj: any) => {
                                valueObj?.value &&
                                  formikProps.setFieldValue(
                                    "gameType",
                                    valueObj.value
                                  );
                              }}
                              onBlur={formikProps.handleBlur}
                              isDisabled={true}
                            />
                          </div>
                          <div className='mb-2 p-3 categoryDescription-react-rtl-container'>
                            <ReactRte
                              value={formikProps?.values?.categoryDescription}
                              setValue={(value: any) => {
                                formikProps.setFieldValue(
                                  "categoryDescription",
                                  value
                                );
                              }}
                              onBlur={() => {
                                formikProps.setFieldTouched(
                                  "categoryDescription",
                                  true
                                );
                              }}
                              error={
                                (!!formikProps.errors.categoryDescription &&
                                  formikProps.touched
                                    .categoryDescription) as boolean
                              }
                            />
                            {formikProps.errors.categoryDescription &&
                              formikProps.touched.categoryDescription && (
                                <FormHelperText className='text-danger'>
                                  {
                                    formikProps?.errors
                                      ?.categoryDescription as string
                                  }
                                </FormHelperText>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mt-4'>
                    <div className='text-center d-flex justify-content-end gap-2'>
                      {isEdit && (
                        <Button
                          variant='outlined'
                          color='error'
                          onClick={() => {
                            navigate(`/category`);
                          }}
                        >
                          Cancel
                        </Button>
                      )}
                      <PrimaryButton
                        type='submit'
                        variant='contained'
                        className='btn btn-primary'
                        disabled={
                          formikProps.isSubmitting ||
                          (isEdit && !formikProps.dirty)
                        }
                        loading={formikProps.isSubmitting}
                        children={isEdit ? "Update" : "Submit"}
                      />
                      {!isEdit && (
                        <PrimaryButton
                          type='Reset'
                          variant='outlined'
                          children={"Reset"}
                        />
                      )}
                    </div>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default CreateCategory;
